import React from "react";

import title_decoration from "../assets/images/title-decoration.png";
import br_1 from "../assets/images/profile/br_1.jpg";
import br_2 from "../assets/images/profile/br_2.jpg";
import br_3 from "../assets/images/profile/br_3.jpg";
import br_4 from "../assets/images/profile/br_4.jpg";
import br_5 from "../assets/images/profile/br_5.jpg";
import br_6 from "../assets/images/profile/br_6.jpg";
import br_7 from "../assets/images/profile/br_7.jpg";
import br_8 from "../assets/images/profile/br_8.jpg";
import br_9 from "../assets/images/profile/br_9.jpg";
import br_10 from "../assets/images/profile/br_10.jpg";
import br_11 from "../assets/images/profile/br_11.jpg";
import br_12 from "../assets/images/profile/br_12.jpg";
import br_13 from "../assets/images/profile/br_13.jpg";
import br_14 from "../assets/images/profile/br_14.jpg";
import br_15 from "../assets/images/profile/br_15.jpg";
import br_16 from "../assets/images/profile/br_16.jpg";


function Home() {
  return (
    <body>
      <main className="main-content services-wrapper brochure">
        <div className="page__banner">
            <div className="container">
                <div className="banner-content">
                    <h2 className="title">NTS Permit</h2>
                    
                </div>
            </div>
        </div>
        
        <div className="content-wrapper">
            <div className="container">
                <div className="col-2-grid left-grid">
                    <div className="list">
                        <ul>
                            <li>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="#F14241" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" className="size-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="m3.75 13.5 10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75Z" />
                                </svg>
                                <span>
                                    Class 3 driver
                                </span>
                            </li>
                            <li>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="#F14241" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" className="size-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="m3.75 13.5 10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75Z" />
                                </svg>
                                <span>
                                    Civil Drafter
                                </span>
                            </li>
                            <li>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="#F14241" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" className="size-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="m3.75 13.5 10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75Z" />
                                </svg>
                                <span>
                                    Quantity Surveyor
                                </span>
                            </li>
                            <li>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="#F14241" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" className="size-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="m3.75 13.5 10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75Z" />
                                </svg>
                                <span>
                                    Mechanical Engineer
                                </span>
                            </li>
                            <li>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="#F14241" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" className="size-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="m3.75 13.5 10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75Z" />
                                </svg>
                                <span>
                                    Cnc machinist
                                </span>
                            </li>
                            <li>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="#F14241" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" className="size-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="m3.75 13.5 10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75Z" />
                                </svg>
                                <span>
                                    Mechanical Drafter
                                </span>
                            </li>
                            <li>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="#F14241" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" className="size-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="m3.75 13.5 10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75Z" />
                                </svg>
                                <span>
                                    Electrical Engineer
                                </span>
                            </li>
                            <li>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="#F14241" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" className="size-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="m3.75 13.5 10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75Z" />
                                </svg>
                                <span>
                                    Electrical Drafter
                                </span>
                            </li>
                            <li>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="#F14241" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" className="size-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="m3.75 13.5 10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75Z" />
                                </svg>
                                <span>
                                    South Indian Cook
                                </span>
                            </li>
                            <li>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="#F14241" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" className="size-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="m3.75 13.5 10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75Z" />
                                </svg>
                                <span>
                                    North Indian Cook
                                </span>
                            </li>
                            <li>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="#F14241" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" className="size-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="m3.75 13.5 10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75Z" />
                                </svg>
                                <span>
                                    Admin
                                </span>
                            </li>
                            <li>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="#F14241" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" className="size-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="m3.75 13.5 10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75Z" />
                                </svg>
                                <span>
                                    Waiter
                                </span>
                            </li>
                            <li>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="#F14241" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" className="size-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="m3.75 13.5 10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75Z" />
                                </svg>
                                <span>
                                    Nurse
                                </span>
                            </li>
                        </ul>
                    </div>
                    <div className="image-section">
                        <div className="image-grid">
                            <div className="row1 ig-4">
                                <div className="image"><img src={br_1} alt="" /></div>
                                <div className="image"><img src={br_2} alt="" /></div>
                                <div className="image"><img src={br_3} alt="" /></div>
                                <div className="image"><img src={br_4} alt="" /></div>
                            </div>
                            <div className="row2 ig-4">
                                <div className="image"><img src={br_5} alt="" /></div>
                                <div className="image"><img src={br_6} alt="" /></div>
                                <div className="image"><img src={br_7} alt="" /></div>
                                <div className="image"><img src={br_8} alt="" /></div>
                            </div>
                            <div className="row3 ig-4">
                                <div className="image"><img src={br_9} alt="" /></div>
                                <div className="image"><img src={br_10} alt="" /></div>
                                <div className="image"><img src={br_11} alt="" /></div>
                                <div className="image"><img src={br_12} alt="" /></div>
                            </div>
                            <div className="row4 ig-4">
                                <div className="image"><img src={br_13} alt="" /></div>
                                <div className="image"><img src={br_14} alt="" /></div>
                                <div className="image"><img src={br_15} alt="" /></div>
                                <div className="image"><img src={br_16} alt="" /></div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </main>
      
    </body>
  );
}

export default Home;
