import React from 'react';
import '../assets/css/RightNav.css';

const RightNav = ({ isOpen, toggleNav }) => {
  return (
    <nav className={`right-nav ${isOpen ? 'open' : ''}`}>
      <a href="#" className="close-btn" onClick={toggleNav}>&times;</a>
      <a href="./">Home</a>
      <a href="./about">About</a>
      <a href="./service">Services</a>
      <a href="/jobopenings">Job Openings</a>
      <a href="./jobseekers">Job Seekers</a>
      <a href="./contactUs">Contact Us</a>
      <div className="content-right">
                                <div className="info-top-right">
                                
                                     
                                   
                                    <a href="tel:04367255044"><i className="fa fa-phone"></i> 04367 255044 </a><br/>
                                    <a href="tel:+916379797483"><i className="fa fa-mobile"></i> +91 63797 97483</a><br/>
                                    <a target="_blank" rel="noopener noreferrer" href="https://m.facebook.com/groups/1676797995867522/?ref=share&mibextid=NSMWBT"><i className="fa fa-facebook"></i> </a> 
                                    <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/krplacementservices/"><i className="fa fa-linkedin"></i> </a> 
                                    <a target="_blank" rel="noopener noreferrer" href="https://whatsapp.com/channel/0029Va5eEEO7YSd5eC8Adw0F"><i className="fa fa-whatsapp"> </i></a>
                                    <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/krplacementservices/"><i className="fa fa-instagram"> </i></a>
                                </div>
                            </div>
    </nav>
  );
};

export default RightNav;