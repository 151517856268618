import React from "react";

import title_decoration from "../assets/images/title-decoration.png";
import hr from "../assets/images/hr.jpg";
import Recruitment from "../assets/images/Recruitment.jpg";
import Staffing from "../assets/images/Staffing.jpg";
import Facility_management from "../assets/images/Facility-management.jpg";
import verification from "../assets/images/verification.jpg";
import Consultant from "../assets/images/Consultant.jpg";

{/*import '../assets/css/nivo-slider.css';
import '../assets/css/owl.carousel.min.css';
import '../assets/css/owl.theme.default.min.css';*/}

function Home() {
  return (
    <body>
       <main className="main-content services-wrapper">
        <div className="page__banner">
            <div className="container">
                <div className="banner-content">
                    <h2 className="title">Our Services</h2>
                    <p className="description">
                        We are a Team of Trusted & Experienced HR Experts ready to serve you
                    </p>
                </div>
            </div>
        </div>
        
        <div className="content-wrapper">
            <div className="container">

                
                <div className="two-col-grid">
                    <div className="grid-child">
                        <div className="content__wrapper">
                            <div className="slideDown-animate-wrapper title-font">
                                <h2 className="row-title">HR Solution</h2>
                                <img decoding="async" width="513" height="50" src={title_decoration} className="" alt="Image"/>
                            </div>

                            <div className="row-description-wrapper">
                                <p>We are offering a wide array of HR Solutions. Our professionals have experience that offering this HR solution in the best possible way. In addition to this, these HR solution are offered using quality tested inputs within a limited period of time. </p>
                            </div>
                        </div>
                    </div>
                    <div className="grid-child">
                        <div className="images__wrapper">
                            <img src={hr} alt="Image"  />
                        </div>
                    </div>
                </div>

                
                <div className="two-col-grid">
                    <div className="grid-child">
                        <div className="images__wrapper">
                            <img src={Recruitment} alt="Image"  />
                        </div>
                    </div>
                    <div className="grid-child">
                        <div className="content__wrapper">
                            <div className="slideDown-animate-wrapper title-font">
                                <h2 className="row-title">Recruitment ( Domestic / Overseas )</h2>
                                <img decoding="async" width="513" height="50" src={title_decoration} className="" alt="Image"/>
                            </div>

                            <div className="row-description-wrapper">
                                <p>
                                    We have successfully embarked our identity among the companies with our Domestic and International Recruitment Consultants. We are fully supported with an expert team of professionals and some industry specialists who are abreast with the requirements of a specific industry. Other than this, we also offer support of our HR professionals for conducting interviews and selection tests to get the right candidate for the right job role.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                
                <div className="two-col-grid">
                    <div className="grid-child">
                        <div className="content__wrapper">
                            <div className="slideDown-animate-wrapper title-font">
                                <h2 className="row-title">Staffing (Permanent / Contract)</h2>
                                <img decoding="async" width="513" height="50" src={title_decoration} className="" alt="Image"/>
                            </div>

                            <div className="row-description-wrapper">
                                <p>
                                    We are offering a wide array of HR Solutions. Our professionals have experience that offering this HR solution in the best possible way. In addition to this, these HR solution are offered using quality tested inputs within a limited period of time. 
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="grid-child">
                        <div className="images__wrapper">
                            <img src={Staffing} alt="Image"  />
                        </div>
                    </div>
                </div>

                
                <div className="two-col-grid">
                    <div className="grid-child">
                        <div className="images__wrapper">
                            <img src={Facility_management} alt="Image"  />
                        </div>
                    </div>
                    <div className="grid-child">
                        <div className="content__wrapper">
                            <div className="slideDown-animate-wrapper title-font">
                                <h2 className="row-title">Facility Management Services</h2>
                                <img decoding="async" width="513" height="50" src={title_decoration} className="" alt="Image"/>
                            </div>

                            <div className="row-description-wrapper">
                                <p>
                                    We are also the largest staffing solutions provider amongst the facility management companies. we as industry leader offer varied solutions to a host of multinational and Indian corporates across the country. We help customers shape their growth around the key drivers of technology, productivity and cost-effectiveness.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                
                <div className="two-col-grid">
                    <div className="grid-child">
                        <div className="content__wrapper">
                            <div className="slideDown-animate-wrapper title-font">
                                <h2 className="row-title">Background Verification</h2>
                                <img decoding="async" width="513" height="50" src={title_decoration} className="" alt="Image"/>
                            </div>

                            <div className="row-description-wrapper">
                                <p>
                                    We understand that how much effort required for an organization to hire a suitable candidate for any position. Our Employment Background Check plays a vital role in the process of screening the candidates and helps the management to have a better control over their Human Capital. Through our quality Employment Background Checks, we help organizations to hire best candidates; and also prevent from hiring frauds and criminals onboard.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="grid-child">
                        <div className="images__wrapper">
                            <img src={verification} alt="Image"  />
                        </div>
                    </div>
                </div>

                
                <div className="two-col-grid">
                    <div className="grid-child">
                        <div className="images__wrapper">
                            <img src={Consultant} alt="Image"  />
                        </div>
                    </div>
                    <div className="grid-child">
                        <div className="content__wrapper">
                            <div className="slideDown-animate-wrapper title-font">
                                <h2 className="row-title">Education Consultant</h2>
                                <img decoding="async" width="513" height="50" src={title_decoration} className="" alt="Image"/>
                            </div>

                            <div className="row-description-wrapper">
                                <p>
                                    One stop solutions for your dreams of studying abroad. We provide consultancy and also assist you on securing your admissions in Universities abroad. Consultancy Services provides the best of the educations offered in the renowned Universities and college campuses to pursue your eduction dreams and come out in flying out colours.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
      
    </body>
  );
}

export default Home;
