import React from "react";

import title_decoration from "../assets/images/title-decoration.png";
import job_seeker from "../assets/images/job-seeker.jpg";

{/*import '../assets/css/nivo-slider.css';
import '../assets/css/owl.carousel.min.css';
import '../assets/css/owl.theme.default.min.css';*/}

function Home() {
  return (
    <body>
      <main className="main-content job_seeking_wrapper">
        <div className="page__banner">
            <div className="container">
                <div className="banner-content">
                    <h2 className="title">Job Seekers</h2>
                    <p className="description">
                        Career Opportunities
                    </p>
                </div>
            </div>
        </div>
        
        <div className="content-wrapper">
            <div className="container">

                
                <div className="two-col-grid">
                    <div className="grid-child">
                        <div className="content__wrapper">
                            <div className="slideDown-animate-wrapper title-font">
                                <h2 className="row-title">Post Your CV</h2>
                                <img decoding="async" width="513" height="50" src={title_decoration} className="" alt="Image"/>
                            </div>

                            <div className="cv-form-wrapper">
                                <form className="cv-form">
                                    <fieldset>
                                        <input type="text" className="form-input" name="fullname" placeholder="FullName" />
                                    </fieldset>
                                    <fieldset>
                                        <input type="email" className="form-input" name="email" placeholder="E-mail" />
                                    </fieldset>
                                    <fieldset>
                                        <input type="tel" className="form-input" name="mobile" placeholder="Mobile No" />
                                    </fieldset>
                                    <fieldset>
                                        <input type="text" className="form-input" name="location" placeholder="Current Location" />
                                    </fieldset>
                                    <fieldset>
                                        <input type="text" className="form-input" name="Qualification" placeholder="Qualification" />
                                    </fieldset>
                                    <fieldset>
                                        <input type="number" className="form-input" name="experience" placeholder="Experience (in years)" />
                                    </fieldset>
                                    <fieldset>
                                        <input type="text" className="form-input" name="keyskills" placeholder="Key-Skills" />
                                    </fieldset>
                                    <fieldset>
                                        <input type="text" className="form-input" name="designation" placeholder="Designation" />
                                    </fieldset>
                                    <fieldset>
                                        <input type="number" className="form-input" name="c-ctc" placeholder="Current CTC" />
                                    </fieldset>
                                    <fieldset>
                                        <input type="number" className="form-input" name="e-ctc" placeholder="Expected CTC" />
                                    </fieldset>
                                    <fieldset>
                                        <label>Upload Your Resume <span className="required-star">*</span></label>
                                        <input type="file" accept="pdf" className="" />
                                    </fieldset>
                                    <fieldset>
                                        <label>Declaration</label>
                                        <div className="dec-checkbox-wrapper">
                                            <input type="checkbox" />
                                            
                                               I agree to the terms and conditions by KR Placement Services Pvt Ltd
                                            
                                        </div>
                                    </fieldset>
                                    <fieldset>
                                        <button type="submit" className="form-submit">
                                            Submit
                                        </button>
                                    </fieldset>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="grid-child">
                        <div className="images__wrapper">
                            <img src={job_seeker} alt="Image"  />
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </main>
      
    </body>
  );
}

export default Home;
