import React from "react";

import title_decoration from "../assets/images/title-decoration.png";
import companyprofile from "../assets/images/companyprofile.png";

{/*import '../assets/css/nivo-slider.css';
import '../assets/css/owl.carousel.min.css';
import '../assets/css/owl.theme.default.min.css';*/}

function Home() {
  return (
    <body>
      <main className="main-content job_opening-wrapper">
        <div className="page__banner">
            <div className="container">
                <div className="banner-content">
                    <h2 className="title">Job Openings</h2>
                    <p className="description">
                        Trusted job recruitment agency
                    </p>
                </div>
            </div>
        </div>
        
        <div className="content-wrapper">
            <div className="container">
                

                <div className="job-opening-table-wrapper">
                    <div className="slideDown-animate-wrapper title-font">
                        <h2 className="row-title">Overseas Job Openings</h2>
                        <img decoding="async" width="513" height="50" src="https://fortumaxcareer.com/wp-content/uploads/2020/08/m-1.png" className="" alt="Image"/>
                    </div>
                    <div>
                        <table className="job-opening-table">
                            <thead>
                                <tr>
                                    <th className="primary-txt">
                                        <h2>
                                            Positions
                                        </h2>
                                    </th>
                                    <th className="primary-txt">
                                        <h2>
                                            Qualification
                                        </h2>
                                    </th>
                                    <th className="primary-txt">
                                        <h2>
                                            Submit Resume
                                        </h2>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <strong>Accounts Executive</strong>
                                    </td>
                                    <td>
                                        <strong>Degree</strong>
                                    </td>
                                    <td>
                                        <strong>
                                            <a href="#">Apply Online</a>
                                        </strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Accounts Executive</strong>
                                    </td>
                                    <td>
                                        <strong>Degree</strong>
                                    </td>
                                    <td>
                                        <strong>
                                            <a href="#">Apply Online</a>
                                        </strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Accounts Executive</strong>
                                    </td>
                                    <td>
                                        <strong>Degree</strong>
                                    </td>
                                    <td>
                                        <strong>
                                            <a href="#">Apply Online</a>
                                        </strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Accounts Executive</strong>
                                    </td>
                                    <td>
                                        <strong>Degree</strong>
                                    </td>
                                    <td>
                                        <strong>
                                            <a href="#">Apply Online</a>
                                        </strong>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </main>
      
    </body>
  );
}

export default Home;
