import React from "react";

import b_image from "../assets/images/b-image.png";
import our_services_1 from "../assets/images/our_services_1.png";
import hr from "../assets/images/hr.jpg";
import Recruitment from "../assets/images/Recruitment.jpg";
import Staffing from "../assets/images/Staffing.jpg";
import Facility_management from "../assets/images/Facility-management.jpg";
import verification from "../assets/images/verification.jpg";
import Consultant from "../assets/images/Consultant.jpg";
import slider1 from "../assets/images/slider1.jpg";
import slider2 from "../assets/images/slider2.jpeg";
import slider3 from "../assets/images/slider3.png";
import slider4 from "../assets/images/slider2.jpg";
import home_1 from "../assets/images/home_1.jpg";
import Our_aspirants from "../assets/images/Our_aspirants.jpg";
import Our_commitments from "../assets/images/Our_commitments.jpg";
import Our_networks from "../assets/images/Our_networks.jpg";
import why_choose4 from "../assets/images/why-choose4.jpg";
import why_choose5 from "../assets/images/why-choose5.jpg";
import SimpleImageSlider from "react-simple-image-slider";
import { useNavigate } from 'react-router-dom';

//import '../assets/css/nivo-slider.css';
//import '../assets/css/owl.carousel.min.css';
//import '../assets/css/owl.theme.default.min.css';
//import '../assets/js/jquery.nivo.slider.js';
///import "../assets/js/owl.carousel.min.js";
//import "../assets/js/home.js";
const images = [
    { url: slider1 },
    { url: slider3 },
    { url: slider4 },
  ];


function Home() {
    const navigate = useNavigate();
  return (
    <body>
        <main className="main-content">
            <div className="container-fluid">
                <div className="hero-slider">
                    <div id="slider" className="nivoSlider">
                        <SimpleImageSlider
                            width={'100%'}
                            height={400}
                            images={images}
                            showBullets={false}
                            showNavs={false}
                            autoPlay={true}
                          />
                        
                    </div>
                </div>
            </div>

            <div className="welcome-section">
                <div className="container">
                    <div className="two-col-grid">
                        <div className="grid-child">
                            <div className="images__wrapper">
                                <img src={home_1} alt="" />
                            </div>
                        </div>
                        <div className="grid-child">
                            <div className="content__wrapper">
                                <div className="slideDown-animate-wrapper title-font">
                                    <h2 className="row-title">Welcome to KR Placement Services</h2>
                                    <img decoding="async" width="513" height="50" src={b_image} className="" alt="Image"/>
                                </div>

                                <div className="row-description-wrapper">
                                    <p>KR Placement Services is a preferred talent acquisition partner to Multinationals and leading Indian businesses. This combined with our role as trusted consultants for professionals translates into our core capability – Building Careers and Building Organisations. </p>
                                </div>

                                <div className="action_wrapper text-center">
                                    <button className="hire-btn">
                                        View more
                                    </button>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>

        <div className="our-services">
            <div className="container">
                <div className="slideDown-animate-wrapper title-font text-center">
                    <h2 className="row-title">Our services</h2>
                    <img  src={b_image} className="" alt="Image"/>
                </div>
                <div   className="flip-box-area">
                        <div onClick={()=>navigate('/hrSolutions')} className="flex-box-col">
                            <div  className="flip-box box1">
                                <div className="flip-box-inner">
                                    <div className="flip-box-front">
                                        <div className="image">
                                            <img className="br-40" src={hr} alt="" />
                                        </div>
                                        <div className="f-title">HR Solution</div>
                                    </div>
                                    <div   className="flip-box-back">
                                        <div className="back-content">
                                            <p>HR Management and Training Consultancy</p>
                                            <a href="#"> View more</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div onClick={()=>navigate('/recruitment')} className="flex-box-col">
                            <div className="flip-box box1">
                                <div className="flip-box-inner">
                                    <div className="flip-box-front">
                                        <div className="image">
                                            <img className="br-40" src={Recruitment} alt="" />
                                        </div>
                                        <div className="f-title">Recruitment</div>
                                    </div>
                                    <div className="flip-box-back">
                                        <div className="back-content">
                                            <p>Recruitment</p>
                                            <a href="#"> View more</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div onClick={()=>navigate('/staffing')} className="flex-box-col">
                            <div className="flip-box box1">
                                <div className="flip-box-inner">
                                    <div className="flip-box-front">
                                        <div className="image">
                                            <img className="br-40" src={Staffing} alt="" />
                                        </div>
                                        <div className="f-title">Staffing</div>
                                    </div>
                                    <div className="flip-box-back">
                                        <div className="back-content">
                                            <p>Staffing</p>
                                            <a href="#"> View more</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div onClick={()=>navigate('/management')} className="flex-box-col">
                            <div className="flip-box box1">
                                <div className="flip-box-inner">
                                    <div className="flip-box-front">
                                        <div className="image">
                                            <img className="br-40" src={Facility_management} alt="" />
                                        </div>
                                        <div className="f-title">Facility Management Services</div>
                                    </div>
                                    <div className="flip-box-back">
                                        <div className="back-content">
                                            <p>Staffing Management Services</p>
                                            <a href="#"> View more</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div onClick={()=>navigate('/verification')} className="flex-box-col">
                            <div className="flip-box box1">
                                <div className="flip-box-inner">
                                    <div className="flip-box-front">
                                        <div className="image">
                                            <img className="br-40" src={verification} alt="" />
                                        </div>
                                        <div className="f-title">Background Verification</div>
                                    </div>
                                    <div className="flip-box-back">
                                        <div className="back-content">
                                            <p>Background Verification</p>
                                            <a href="/verification" > View more</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div onClick={()=>navigate('/education')} className="flex-box-col">
                            <div className="flip-box box1">
                                <div className="flip-box-inner">
                                    <div className="flip-box-front">
                                        <div className="image">
                                            <img className="br-40" src={Consultant} alt="" />
                                        </div>
                                        <div className="f-title">Education Consultant</div>
                                    </div>
                                    <div className="flip-box-back">
                                        <div className="back-content">
                                            <p>Education Consultant</p>
                                            <a href="./education"> View more</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        

       

        <div className="our-sections">
            <div className="container">
                <div className="our-sections-grid">
                    <div className="grid-box">
                        <div className="slideDown-animate-wrapper title-font">
                            <h2 className="row-title">Our Commitment</h2>
                            <img decoding="async" width="513" height="50" src={b_image} className="" alt="Image"/>
                        </div>
                        <div onClick={()=>console.log('sdvhjs')} className="image">
                            <img className="br-40" src={Our_commitments} alt="" />
                        </div>
                        <div className="content">
                            <p>Ensuring that we maintain a transparent service from start to finish. Integrity and honesty mean a lot to us and we aim to keep you in the loop at every stage of the process and help explain every part of the process in detail. We actively search for a ‘people fit’ and not just a match on qualifications and area.</p>
                        </div>
                    </div>
                    <div className="grid-box">
                        <div className="slideDown-animate-wrapper title-font">
                            <h2 className="row-title">Our Networks</h2>
                            <img decoding="async" width="513" height="50" src={b_image} className="" alt="Image"/>
                        </div>
                        <div className="image">
                            <img className="br-40" src={Our_networks} alt="" />
                        </div>
                        <div className="content">
                            <p>With full international networks, we act locally and operate Globally</p>
                            <ul>
                                <li>India</li>
                                <li>Singapore</li>
                                <li>Malaysia</li>
                                <li>Canada</li>
                                <li>Australia</li>
                                <li>UAE</li>
                                <li>UK</li>
                            </ul>
                            </div>
                    </div>
                    
                    <div className="grid-box">
                        <div className="slideDown-animate-wrapper title-font">
                            <h2 className="row-title">Our Aspirants</h2>
                            <img decoding="async" width="513" height="50" src={b_image} className="" alt="Image"/>
                        </div>
                        <div className="image">
                            <img className="br-40" src={Our_aspirants} alt="" />
                        </div>
                        <div className="content">
                            <p>We are advisory and consultative – we do not simply match CV’s to our clients requirements but assist talented professionals to locate that next career step.</p>
                            <p>We recruit not only for the immediate vacancy but importantly for the next step after that to ensure your continued development.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </main> 
      
    </body>
  );
}

export default Home;
