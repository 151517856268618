import React from "react";

import title_decoration from "../assets/images/title-decoration.png";
import companyprofile from "../assets/images/companyprofile.png";


function Home() {
  return (
    <body>
      <main className="main-content about-wrapper">
        <div className="page__banner">
            <div className="container">
                <div className="banner-content">
                    <h2 className="title">About Us</h2>
                    <p className="description">
                        Our Journey so far…
                    </p>
                </div>
            </div>
        </div>
        
        <div className="content-wrapper">
            <div className="container">

                {/*<!-- Company Profile -->*/}
                <div className="two-col-grid">
                    <div className="grid-child">
                        <div className="content__wrapper">
                            <div className="slideDown-animate-wrapper title-font">
                                <h2 className="row-title">Company Profile</h2>
                                <img decoding="async" width="513" height="50" src={title_decoration} className="" alt="Image"/>
                            </div>

                            <div className="row-description-wrapper">
                                <p>KR Placement Services, the leading global workforce solutions company, helps organizations transform in a fast-changing world of work by sourcing, assessing, developing and managing the talent that enables them to win. We develop innovative solutions for many organizations, providing them with skilled talent while finding meaningful, sustainable employment for millions of people across a wide range of industries and skills. Our expert family of brands – Manpower, Experts and Talent Solutions – creates substantial value for candidates and clients across the countries and territories. </p>
                            </div>
                        </div>
                    </div>
                    <div className="grid-child">
                        <div className="images__wrapper">
                            <img src={companyprofile} alt="Image"  />
                        </div>
                    </div>
                </div>

                <div className="two-col-grid vision_mission-wrapper">
                    <div className="grid-child vision-wrapper">
                        <div className="row-description-wrapper">
                            <h2 className="title">VISION</h2>
                            <p>We work hard to achieve our goals together as a team with a clear shared purpose. We adapt to our clients changing needs as well changes in the market to make sure we are a business of growth, success and happiness.</p>
                        </div>
                    </div>
                    <div className="grid-child mission-wrapper">
                        <div className="row-description-wrapper">
                            <h2 className="title">MISSION</h2>
                            <p>Together we communicate on a personal level listening to the individual need and providing a choice of solution. We deliver what we promise building trust, building fans, one client and candidate at a time. If we can over deliver we do. We create a tailored, transparent recruitment experience, encouraging honest feedback and continual improvement.</p>
                        </div>
                    </div>
                </div>

                <div className="two-col-grid">
                    <div className="grid-child">
                        <div className="content__wrapper">
                            <div className="slideDown-animate-wrapper title-font">
                                <h2 className="row-title">Values</h2>
                                <img decoding="async" width="513" height="50" src={title_decoration} className="" alt="Image"/>
                            </div>

                            <div className="row-description-wrapper">
                                <ul className="value-ul ul-wrapper">
                                    <li>
                                        <strong>Excellence: </strong>
                                        We will always deliver what we promise, while working to the highest quality standard. We do not promise what we cannot deliver. We over-deliver where possible.
                                    </li>
                                    <li>
                                        <strong>Integrity</strong>
                                        We demonstrate complete integrity, upholding honesty and strong moral principles. We do not compromise the truth.
                                    </li>
                                    <li>
                                        <strong>Passion</strong>
                                        We are passionate about recruitment showing enthusiasm, energy and an understanding of our client and candidate needs.
                                    </li>
                                    <li>
                                        <strong>Respect:</strong>
                                        We treat our clients, candidates and each other with mutual respect and sensitivity, embracing individual diversity.
                                    </li>
                                    <li>
                                        <strong>Clarity: </strong>
                                        We ensure clear communication in everything we do. We encourage the open exchange of ideas, opinions and views related to the business.
                                    </li>
                                    <li>
                                        <strong>Accountability: </strong>
                                        We accept responsibility for our actions. We use experience and good judgement to support the business.
                                    </li>
                                    <li>
                                        <strong>Community: </strong>
                                        We support each other as a community, caring for each other personally and professionally. We are loyal. We also support external communities and the environment through our charity work and internal environmental policy.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="grid-child">
                        <div className="content__wrapper">
                            <div className="slideDown-animate-wrapper title-font">
                                <h2 className="row-title">What We Do</h2>
                                <img decoding="async" width="513" height="50" src={title_decoration} className="" alt="Image"/>
                            </div>

                            <div className="row-description-wrapper">
                                <p>
                                    We are an executive search & recruitment company with global reach, with a deep-rooted expertise in all Sectors, recruiting for Permanent, and Contract vacancies. We partner our clients to access executive talent through search, market research, and human capital advisory. Having a global capability to work with our partner network to deliver worldwide.
                                </p>
                                <ul className="what_we_do-ul ul-wrapper">
                                    <li>HR Solutions</li>
                                    <li>Recruitment</li>
                                    <li>Staffing</li>
                                    <li>Facility Management Services</li>
                                    <li>Background Verification</li>
                                    <li>Education Consultant</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </main>
      
    </body>
  );
}

export default Home;
