import "./App.css";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Home from "./Pages/Home";
import Services from "./Pages/Services";
import About from "./Pages/About";
import JobSeek from "./Pages/JobSeek";
import ContactUs from "./Pages/ContactUs";
import Job from "./Pages/job";
import Hr from "./Pages/Hr";
import Pcmpermit from "./Pages/pcmpermit";
import ShipyardPermit from "./Pages/ShipyardPermit";
import Ntspermit from "./Pages/Ntspermit";
import Workpermit from "./Pages/Workpermit";
import Training from "./Pages/Training";
import ScrollToTop from './Pages/ScrollToTop';
import site_logo from "./assets/images/site_logo.png";
import SimpleImageSlider from "react-simple-image-slider";
import React, { Component } from "react";
import Mauritius_flag from "./assets/images/flags/Mauritius_flag.png";
import Bahrain_flag from "./assets/images/flags/Bahrain_flag.png";
import russia_flag from "./assets/images/flags/russia_flag.png";
import Kuwait_flag from "./assets/images/flags/Kuwait_flag.png";
import maladives_flag from "./assets/images/flags/maladives_flag.png";
import oman_flag from "./assets/images/flags/oman_flag.png";
import Brunei_flag from "./assets/images/flags/Brunei_flag.png";
import Uae_flag from "./assets/images/flags/Uae_flag.png";
import malaysia_flag from "./assets/images/flags/malaysia_flag.png";
import Saudi_Arabia_flag from "./assets/images/flags/Saudi_Arabia_flag.png";
import Singapore_flag from "./assets/images/flags/Singapore_flag.png";
import Qatar_flag from "./assets/images/flags/Qatar_flag.png";
import RightNav from './Pages/RightNav';


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav_selected: "home",
      isClicked: false,
      navOpen:false
    };
  }

 toggleNav = () => {
    this.setState({
        navOpen:!this.state.navOpen
    })
  };
  render() {
    const width = window.innerWidth;
    console.log("dhchsdchs", width);
    return (
      <Router>
          <ScrollToTop />
        <nav>
          
            <body>
                <div className="main-header top-header">
                    <nav className="navbar navbar-expand-lg flex-col" id="mainnav">
                        <div className="container">
                            <div className="logo">
                                <a className="navbar-brand" href="./index.html">
                                    <img src={site_logo} alt="logo" className="" />
                                </a>
                            </div>
                            <div className="nav-wrap">
                                <button onClick={()=>
                                this.toggleNav()} className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                                <RightNav isOpen={this.state.navOpen} toggleNav={this.toggleNav} /> 
                                <div className="collapse navbar-collapse" id="navbarNavDropdown">
                                    <ul id="menu-content" className="navbar-nav menu">
                                        <li id="menu-item-1" className="menu-item nav-item"><a href="./" className="nav-link">Home</a></li>
                                        <li id="menu-item-2" className="menu-item nav-item"><a href="./about" className="nav-link">About Us</a></li>
                                        <li id="menu-item-3" className="menu-item nav-item"><a href="./service" className="nav-link">Our Services</a>
                                            <ul className="sub-menu">
                                                <li id="menu-item-4" className="menu-item nav-item"><a href="./hrSolutions" className="nav-link">HR Solution</a></li>
                                                <li id="menu-item-5" className="menu-item nav-item"><a href="./recruitment" className="nav-link">Recruitment</a></li>
                                                <li id="menu-item-6" className="menu-item nav-item"><a href="./staffing" className="nav-link">Staffing</a></li>
                                                <li id="menu-item-7" className="menu-item nav-item"><a href="./management" className="nav-link">Facility Management Services</a></li>
                                                <li id="menu-item-8" className="menu-item nav-item"><a href="./verification" className="nav-link">Background Verification</a></li>
                                                <li id="menu-item-9" className="menu-item nav-item"><a href="./education" className="nav-link">Education Consultant</a></li>
                                            </ul>
                                        </li>
                                        <li id="menu-item-10" className="menu-item nav-item"><a href="./jobopenings" className="nav-link">Job Openings</a></li>
                                        <li id="menu-item-11" className="menu-item nav-item"><a href="./jobseekers" className="nav-link">Job Seekers</a></li>
                                        {/*<li id="menu-item-12" className="menu-item nav-item"><a href="#" className="nav-link">For Studies</a></li>*/}
                                        <li id="menu-item-13" className="menu-item nav-item"><a href="./contactUs" className="nav-link">Contact Us</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="content-right">
                                <div className="info-top-right">
                                
                                     
                                   
                                    <a href="tel:04367255044"><i className="fa fa-phone"></i> 04367 255044 </a><br/>
                                    <a href="tel:+916379797483"><i className="fa fa-mobile"></i> +91 63797 97483</a><br/>
                                    <a target="_blank" rel="noopener noreferrer" href="https://m.facebook.com/groups/1676797995867522/?ref=share&mibextid=NSMWBT"><i className="fa fa-facebook"></i> </a> 
                                    <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/krplacementservices/"><i className="fa fa-linkedin"></i> </a> 
                                    <a target="_blank" rel="noopener noreferrer" href="https://whatsapp.com/channel/0029Va5eEEO7YSd5eC8Adw0F"><i className="fa fa-whatsapp"> </i></a>
                                    <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/krplacementservices/"><i className="fa fa-instagram"> </i></a>
                                </div>
                            </div>
                        </div>
                    </nav>
                    <marquee scrollamount="5" loop="2">
                        <div className="services-slider">
                            <div className="flags">
                                <div className="sybox">
                                    <div className="row ">
                                        <div className="col-4 col-md-4">
                                            <img className="img-fluid " src={Qatar_flag} alt="" />
                                        </div>
                                        <div className="col-8 col-md-8 my-auto">
                                            <h4 className="txt_pri">QATAR</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="sybox">
                                    <div className="row ">
                                        <div className="col-4 col-md-4">
                                            <img className="img-fluid " src={Singapore_flag} alt="" />
                                        </div>
                                        <div className="col-8 col-md-8 my-auto">
                                            <h4 className="txt_pri">Singapore</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="sybox">
                                    <div className="row ">
                                        <div className="col-4 col-md-4">
                                            <img className="img-fluid " src={Saudi_Arabia_flag} alt="" />
                                        </div>
                                        <div className="col-8 col-md-8 my-auto">
                                            <h4 className="txt_pri">Saudi Arabia</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="sybox">
                                    <div className="row ">
                                        <div className="col-4 col-md-4">
                                            <img className="img-fluid " src={malaysia_flag} alt="" />
                                        </div>
                                        <div className="col-8 col-md-8 my-auto">
                                            <h4 className="txt_pri">Malaysia</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="sybox">
                                    <div className="row ">
                                        <div className="col-4 col-md-4">
                                            <img className="img-fluid " src={Uae_flag} alt="" />
                                        </div>
                                        <div className="col-8 col-md-8 my-auto">
                                            <h4 className="txt_pri">Uae</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="sybox">
                                    <div className="row ">
                                        <div className="col-4 col-md-4">
                                            <img className="img-fluid " src={Brunei_flag} alt="" />
                                        </div>
                                        <div className="col-8 col-md-8 my-auto">
                                            <h4 className="txt_pri">Brunei</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="sybox">
                                    <div className="row ">
                                        <div className="col-4 col-md-4">
                                            <img className="img-fluid " src={oman_flag} alt="" />
                                        </div>
                                        <div className="col-8 col-md-8 my-auto">
                                            <h4 className="txt_pri">Oman</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="sybox">
                                    <div className="row ">
                                        <div className="col-4 col-md-4">
                                            <img className="img-fluid " src={maladives_flag} alt="" />
                                        </div>
                                        <div className="col-8 col-md-8 my-auto">
                                            <h4 className="txt_pri">Maladives</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="sybox">
                                    <div className="row ">
                                        <div className="col-4 col-md-4">
                                            <img className="img-fluid " src={Kuwait_flag} alt="" />
                                        </div>
                                        <div className="col-8 col-md-8 my-auto">
                                            <h4 className="txt_pri">Kuwait</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="sybox">
                                    <div className="row ">
                                        <div className="col-4 col-md-4">
                                            <img className="img-fluid " src={russia_flag} alt="" />
                                        </div>
                                        <div className="col-8 col-md-8 my-auto">
                                            <h4 className="txt_pri">Russia</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="sybox">
                                    <div className="row ">
                                        <div className="col-4 col-md-4">
                                            <img className="img-fluid " src={Bahrain_flag} alt="" />
                                        </div>
                                        <div className="col-8 col-md-8 my-auto">
                                            <h4 className="txt_pri">Bahrain</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="sybox">
                                    <div className="row ">
                                        <div className="col-4 col-md-4">
                                            <img className="img-fluid " src={Mauritius_flag} alt="" />
                                        </div>
                                        <div className="col-8 col-md-8 my-auto">
                                            <h4 className="txt_pri">MAURITIUS</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </marquee>
                </div>


      
    
               
                    <Routes>
                      <Route path="/" element={<Home />} />
                      <Route path="/about" element={<About />} />
                      <Route path="/service" element={<Services />} />
                      <Route path="/jobopenings" element={<Job />} />
                      <Route path="/jobseekers" element={<JobSeek />} />
                      <Route path="/contactUs" element={<ContactUs />} />
                      <Route path="/hrSolutions" element={<Hr />} />
                      <Route path="/recruitment" element={<Hr />} />
                      <Route path="/staffing" element={<Hr />} />
                      <Route path="/management" element={<Hr />} />
                      <Route path="/verification" element={<Hr />} />
                      <Route path="/education" element={<Hr />} />
                      <Route path="/pcmpermit" element={<Pcmpermit />} />
                      <Route path="/shipyardpermit" element={<ShipyardPermit />} />
                      <Route path="/ntspermit" element={<Ntspermit />} />
                      <Route path="/workpermit" element={<Workpermit />} />
                      <Route path="/training" element={<Training />} />

                    </Routes>
            <div className="top-footer">
        <div className="container">
            <div className="row">
                <div className="col-md-9">
                    <div className="column-inner">
                        <div className="wrapper">
                            <h2 className="custom_heading wpb_animate_when_almost_visible wpb_slideInDown slideInDown wpb_start_animation animated">We offer a full range of Recruitment Consultancy Services</h2>
                            <div className="empty_space">
                                <span className="vc_empty_space_inner"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-3 button-box">
                    <div className="column-inner">
                        <div className="wrapper">
                            <div className="btn-contact">
                                <a href="#" title="">Contact Us</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>     

    <footer className="footer">
        <div className="footer_background">
            <div className="container">
                <div className="f-widgets">
                    <div className="row">
                        <div className="col-md-4 col-sm-6">
                            <div className="footer_nav_menu">
                                <h4 className="widget-title">Our Industry Expertises</h4>
                                <div className="middle-footer-container">
                                    <ul id="footer-middle" className="menu">
                                        <li className="fmenu-item">
                                            <a href="/pcmpermit">PCM Permit</a>
                                        </li>
                                        <li className="fmenu-item">
                                            <a href="/shipyardpermit">Shipyard Permit</a>
                                        </li>
                                        <li className="fmenu-item">
                                            <a href="/ntspermit">S pass E-pass NTS Permit</a>
                                        </li>
                                        <li className="fmenu-item">
                                            <a href="/workpermit">Work Permit</a>
                                        </li>
                                        <li className="fmenu-item">
                                            <a href="/training">Training</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div id="f_menu-2" className="f_nav_menu">
                                <h4 className="widget-title">Services</h4>
                                <div className="fservices-container">
                                    <ul id="f-services" className="menu">
                                        <li className="fmenu-item">
                                            <a href="./hrSolutions">HR Solution</a>
                                        </li>
                                        <li className="fmenu-item">
                                            <a href="./recruitment">Recruitment</a>
                                        </li>
                                        <li className="fmenu-item">
                                            <a href="./staffing">Staffing</a>
                                        </li>
                                        <li className="fmenu-item">
                                            <a href="./management">Facility Management Services</a>
                                        </li>
                                        <li className="fmenu-item">
                                            <a href="./verification">Background Verification</a>
                                        </li>
                                        <li className="fmenu-item">
                                            <a href="./education">Education Consultant</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div className="widget widget_text">
                                <h4 className="widget-title">KR Placement at Social</h4>
                            </div>
                            <div className="f_socials">
                                <ul className="f-socials">
                                    <li className="instagram">
                                        <a href="https://www.linkedin.com/in/krplacementservices/" target="_blank" rel="alternate" title="#">
                                            <i className="fa fa-instagram"></i> Instagram </a>
                                    </li>
                                    <li className="linkedin">
                                        <a href="https://www.linkedin.com/in/krplacementservices/" target="_blank" rel="alternate">
                                            <i className="fa fa-linkedin"></i> LinkedIn </a>
                                    </li>
                                    <li className="facebook">
                                        <a href="https://whatsapp.com/channel/0029Va5eEEO7YSd5eC8Adw0F" target="_blank" rel="alternate">
                                            <i className="fa fa-whatsapp"></i> Whatsapp </a>
                                    </li>
                                    <li className="facebook">
                                        <a href="https://m.facebook.com/groups/1676797995867522/?ref=share&mibextid=NSMWBT" target="_blank" rel="alternate">
                                            <i className="fa fa-facebook"></i> Facebook </a>
                                    </li>
                                </ul>
                                {/*<!-- /.social --> */} 
                                
                            </div>
                        </div>
                    </div>
                    {/*<!-- /.footer-widgets --> */} 
                    
                </div>
                {/*<!-- /.row --> */} 
                
            </div>
            {/*<!-- /.container --> */} 
            
        </div>
        {/*<!-- Bottom --> */} 
        
        <div className="footer-bottom">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="copyright">
                            <p>Copyright 2024 KR Placement services. All Rights Reserved.</p>
                        </div>
                    </div>
                    {/*<!-- /.col-md-12 --> */} 
                    
                </div>
                {/*<!-- /.row --> */} 
                
            </div>
            {/*<!-- /.container --> */} 
            
        </div>
    </footer>  
                
               
              
            </body>
          
        </nav>
      </Router>
    );
  }
}

export default App;
