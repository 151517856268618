import React from "react";


import br_1 from "../assets/images/profile/br_1.jpg";
import br_2 from "../assets/images/profile/br_2.jpg";
import br_3 from "../assets/images/profile/br_3.jpg";
import br_4 from "../assets/images/profile/br_4.jpg";
import br_5 from "../assets/images/profile/br_5.jpg";
import br_6 from "../assets/images/profile/br_6.jpg";
import br_7 from "../assets/images/profile/br_7.jpg";
import br_8 from "../assets/images/profile/br_8.jpg";
import br_9 from "../assets/images/profile/br_9.jpg";


function Home() {
  return (
    <body>
      <main className="main-content services-wrapper brochure">
        <div className="page__banner">
            <div className="container">
                <div className="banner-content">
                    <h2 className="title">Training</h2>
                </div>
            </div>
        </div>
        
        <div className="content-wrapper">
            <div className="container">
                <div className="col-2-grid left">

                    <div className="list">
                        <ul>
                            <li>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="#F14241" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" className="size-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="m3.75 13.5 10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75Z" />
                                </svg>
                                <span>
                                    Steel Fitter
                                </span>
                            </li>
                            <li>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="#F14241" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" className="size-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="m3.75 13.5 10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75Z" />
                                </svg>
                                <span>
                                    Electrical Fitter
                                </span>
                            </li>
                            <li>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="#F14241" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" className="size-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="m3.75 13.5 10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75Z" />
                                </svg>
                                <span>
                                    Mechanical Fitter
                                </span>
                            </li>
                            <li>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="#F14241" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" className="size-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="m3.75 13.5 10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75Z" />
                                </svg>
                                <span>
                                    3G, 4G, 6G Welders
                                </span>
                            </li>
                            <li>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="#F14241" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" className="size-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="m3.75 13.5 10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75Z" />
                                </svg>
                                <span>
                                    Pipe Fitter
                                </span>
                            </li>
                            <li>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="#F14241" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" className="size-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="m3.75 13.5 10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75Z" />
                                </svg>
                                <span>
                                    Scaffolders
                                </span>
                            </li>
                            <li>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="#F14241" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" className="size-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="m3.75 13.5 10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75Z" />
                                </svg>
                                <span>
                                    Painter
                                </span>
                            </li>
                            <li>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="#F14241" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" className="size-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="m3.75 13.5 10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75Z" />
                                </svg>
                                <span>
                                    Sand Blasters
                                </span>
                            </li>
                        </ul>
                    </div>
                    <div className="image-section">
                        <div className="image-grid row-3">
                            <div className="row1 ig-3">
                                <div className="image"><img src={br_1} alt="" /></div>
                                <div className="image"><img src={br_2} alt="" /></div>
                                <div className="image"><img src={br_3} alt="" /></div>
                            </div>
                            <div className="row2 ig-3">
                                <div className="image"><img src={br_4} alt="" /></div>
                                <div className="image"><img src={br_5} alt="" /></div>
                                <div className="image"><img src={br_6} alt="" /></div>
                            </div>
                            <div className="row3 ig-3">
                                <div className="image"><img src={br_7} alt="" /></div>
                                <div className="image"><img src={br_8} alt="" /></div>
                                <div className="image"><img src={br_9} alt="" /></div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </main>
      
    </body>
  );
}

export default Home;
